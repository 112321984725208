import React from 'react';
import { Button as ButtonAnt, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import clx from 'classnames'

const Button = ({
  text,
  type,
  loading,
  className,
  showTooltip = false,
  descriptionTool = '',
  fullwidth = false,
  ...props
}) => {

  if (showTooltip) {
    return (
      <Tooltip title={descriptionTool} placement="top">
        <ButtonAnt
          {...props}
          loading={loading}
          className={clx(`button-custom ${className}
          ${type}`)}
        />
      </Tooltip>
    )
  }

  return (
    <ButtonAnt
      {...props}
      loading={loading}
      className={clx(`button-custom ${className}
      ${type}`, {"btn-full": fullwidth})}>
      {text}
    </ButtonAnt>
  )
}

export default Button;

Button.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  title: '',
  type: '', //primary, success, add, default, dashed
  text: '',
  loading: false,
  className: '',
};