import { api } from "services/api"

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getCongChucNhanViec: builder.query({
      query: (params) => ({
        url: '/api/nguoi-dung/cong-chuc-nhan-viec',
        method: 'GET',
        params
      }),
    }),
    getLanhDao: builder.query({
      query: (params) => ({
        url: '/api/nguoi-dung/lanh-dao',
        method: 'GET',
        params
      }),
    }),
    getCongChucXuLyGiaiQuyet: builder.query({
      query: (params) => ({
        url: '/api/nguoi-dung/cong-chuc-xu-ly-giai-quyet',
        method: 'GET',
        params
      }),
    }),
    postThayDoiMatKhau: builder.mutation({
      query: (params) => ({
        url: '/api/nguoi-dung/thay-doi-mat-khau',
        method: 'POST',
        body: params
      })
    }),
    putSetPassword: builder.mutation({
      query: (params) => ({
        url: '/api/nguoi-dung/set-password',
        method: 'PUT',
        body: params
      })
    })
  })
})

export const {
  useGetCongChucNhanViecQuery,
  useGetLanhDaoQuery,
  useGetCongChucXuLyGiaiQuyetQuery,
  usePostThayDoiMatKhauMutation,
  usePutSetPasswordMutation
} = userApi;
