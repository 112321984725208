
import React from 'react';
import { Input as InputAnt, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { maxLengthNumber, maxLengthTextarea, maxLengthTextBox } from 'constants/variables';

const Input = ({
  type,
  placeholder,
  controls,
  maxLength,
  ...props
}) => {
  const { TextArea } = InputAnt;
  if (type === 'textArea') {
    return (
      <TextArea
        {...props}
        className={clx('input-textarea', props.className)}
        placeholder={placeholder || 'Nhập'}
        maxLength={maxLengthTextarea || maxLength}
      />);
  }

  if (type === 'number') {
    return (
      <InputNumber {...props}
        className={clx('input-number', props.className)}
        placeholder={placeholder || 'Nhập'}
        controls={controls || false}
        maxLength={maxLengthNumber || maxLength}
      />
    );
  }

  if (type === 'password') {
    return (
      <InputAnt.Password {...props}
        className={clx('input-password', props.className)}
        placeholder={placeholder || 'Nhập'}
        controls={controls || false}
        maxLength={maxLengthNumber || maxLength}
      />
    );
  }
  return (
    <InputAnt
      {...props}
      className={clx('input-text', props.className)}
      placeholder={placeholder || 'Nhập'}
      maxLength={maxLengthTextBox || maxLength}
    />);
}

export default Input;

Input.propTypes = {
  type: PropTypes.string
};

Input.defaultProps = {
  type: ''
};