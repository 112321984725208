import { configureStore } from '@reduxjs/toolkit';
import commonReducer from '../modal/commonSlice';
import tiepCongDanReducer from '../modal/tiepcongdanSlice';
import authReducer from '../modal/authSlice';
import theoDoiGiamSatReducer from '../modal/theoDoiGiamSatSlice';
import thongKeDonCuReducer from '../modal/thongKeDonCuSlice';
import xuLyGiaiQuyetDonReducer from '../modal/xuLyGiaiQuyetDonSlice';
import quanLyNguoiDungReducer from '../modal/quanLyNguoiDungSlide';
import traCuuDonReducer from '../modal/traCuuDonSlice';
import trangChuReducer from '../modal/trangChuSlice';
import coQuanReducer from '../modal/coQuanSlice';
import coQuanQuanLyReducer from '../modal/coQuanQuanLySlice';
import { api } from '../services/api';

export default configureStore({
  reducer: {
    tiepCongDan: tiepCongDanReducer,
    auth: authReducer,
    common: commonReducer,
    theoDoiGiamSat: theoDoiGiamSatReducer,
    traCuuDon: traCuuDonReducer,
    quanLyNguoiDung: quanLyNguoiDungReducer,
    trangChu: trangChuReducer,
    thongKeDonCu: thongKeDonCuReducer,
    xuLyGiaiQuyetDon: xuLyGiaiQuyetDonReducer,
    [api.reducerPath]: api.reducer,
    coQuan: coQuanReducer,
    coQuanQuanLy: coQuanQuanLyReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});
