import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSearch: {},
};

const quanLyNguoiDungSlideSlice = createSlice({
  name: 'quanLyNguoiDung',
  initialState,
  reducers: {
    setDataQuanLyNguoiDung: (state, action) => {
      state.dataSearch = action.payload;
    },
    resetDataQuanLyNguoiDung: (state) => {
      state.dataSearch = {};
    },
  },
});

export const { setDataQuanLyNguoiDung, resetDataQuanLyNguoiDung } = quanLyNguoiDungSlideSlice.actions;
export const selectQuanLyNguoiDungSearch = (state) => state?.quanLyNguoiDung?.dataSearch;
export default quanLyNguoiDungSlideSlice.reducer;
