import React, { useState } from 'react';
import { Layout } from 'antd';
import MasterSidebar from './MasterSidebar';
import MasterHeader from './MasterHeader';

const MasterLayout = (props) => {
  const { children } = props;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      {
        <>
          <MasterSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
          <Layout className="site-layout">
            <MasterHeader collapsed={collapsed} setCollapsed={setCollapsed} />
            {children}
          </Layout>
        </>
      }
    </Layout>
  );
}

export default MasterLayout;
