const fileAccept = '.doc, .docx, .pdf, .xls, .xlsx, .rar, .zip, .jpg, .jpeg, .png';
const fileAcceptArray = ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.rar', '.zip', '.jpg', '.jpeg', '.png']
const UrlWithoutLayouts = ['/signup', '/signin', '/404', '/tra-cuu-cong-dan'];
const authConfig = {
  API_URL: process.env.REACT_APP_API_URL,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  CLIENT_ID: 'default-client',
  CLIENT_SECRET: 'password'
}
const phoneNumber = '0905 777 876';

export { fileAccept, authConfig, UrlWithoutLayouts, phoneNumber, fileAcceptArray };