import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSearch: {},
};

const coQuanSlice = createSlice({
  name: 'coQuan',
  initialState,
  reducers: {
    setDataCoQuan: (state, action) => {
      state.dataSearch = action.payload;
    },
    resetDataCoQuan: (state) => {
      state.dataSearch = {};
    },
  },
});

export const { setDataCoQuan } = coQuanSlice.actions;
export const selectCoQuanSearch = (state) => state?.coQuan?.dataSearch;
export default coQuanSlice.reducer;
