import { api } from "services/api"

export const enumApi = api.injectEndpoints({
  endpoints: builder => ({
    getPhanLoaiDon: builder.query({
      query: (params) => ({
        url: '/api/enum/phan-loai-don',
        method: 'GET',
        params
      }),
    }),
    getHuongXuLyTiepDan: builder.query({
      query: (params) => ({
        url: '/api/enum/huong-xu-ly-tiep-dan',
        method: 'GET',
        params
      }),
    }),
    getHuongXuLy: builder.query({
      query: (params) => ({
        url: '/api/enum/huong-xu-ly-don',
        method: 'GET',
        params
      }),
    }),
    getTrangThaiDon: builder.query({
      query: (params) => ({
        url: '/api/enum/trang-thai-don',
        method: 'GET',
        params
      }),
    }),
    getTrangThaiDonChuyen: builder.query({
      query: (params) => ({
        url: 'api/enum/trang-thai-don-chuyen',
        method: 'GET',
        params
      }),
    }),
    getLoaiDoiTuongDungDon: builder.query({
      query: (params) => ({
        url: '/api/enum/loai-doi-tuong-dung-don',
        method: 'GET',
        params
      }),
    }),
    getNoiCapGiayTos: builder.query({
      query: (params) => ({
        url: '/api/noi-cap-giay-to',
        method: 'GET',
        params,
      }),
    }),
    getQuocTichs: builder.query({
      query: (params) => ({
        url: '/api/quoc-tich',
        method: 'GET',
        params,
      }),
    }),
    getDonViHanhChinhTree: builder.query({
      query: (params) => ({
        url: '/api/don-vi-hanh-chinh/tree',
        method: 'GET',
        params,
      }),
    }),
    getHuongGiaiQuyetDon: builder.query({
      query: (params) => ({
        url: '/api/enum/huong-giai-quyet-don',
        method: 'GET',
        params,
      }),
    }),
    getKetQuaKetLuanNoiDung: builder.query({
      query: (params) => ({
        url: '/api/enum/ket-qua-ket-luan-noi-dung',
        method: 'GET',
        params,
      }),
    }),
    getLoaiDoiTuongKhieuNai: builder.query({
      query: (params) => ({
        url: '/api/enum/loai-doi-tuong-bi-khieu-nai-to-cao',
        method: 'GET',
        params,
      }),
    }),
    getLinhVucDonThu: builder.query({
      query: (params) => ({
        url: '/api/combobox/linh-vuc-don-thu',
        method: 'GET',
        params,
      }),
    }),
    getLinhVucDonThuChiTiet: builder.query({
      query: (params) => ({
        url: '/api/combobox/linh-vuc-don-thu-chi-tiet',
        method: 'GET',
        params,
      }),
    }),
    getKetQuaGiaiQuyetLanDau: builder.query({
      query: (params) => ({
        url: '/api/enum/ket-qua-giai-quyet-lan-dau',
        method: 'GET',
        params,
      })
    }),
    // Begin theo dõi thực hiện
    getTrangThaiTheoDoiThucHien: builder.query({
      query: (params) => ({
        url: '/api/enum/trang-thai-theo-doi-thuc-hien',
        method: 'GET',
        params,
      }),
    }),
    getKetQuaTheoDoiThucHien: builder.query({
      query: (params) => ({
        url: '/api/enum/ket-qua-theo-doi-thuc-hien',
        method: 'GET',
        params,
      }),
    }),
    // End theo dõi thực hiện

    getDanTocs: builder.query({
      query: (params) => ({
        url: '/api/dan-toc',
        method: 'GET',
        params,
      }),
    }),
    // Theo dõi giám sát
    getTDGSPhanHe: builder.query({
      query: (params) => ({
        url: '/api/enum/theo-doi-giam-sat/phan-he',
        method: 'GET',
        params,
      }),
    }),
    getTDGSTimKiemTheo: builder.query({
      query: (params) => ({
        url: '/api/enum/theo-doi-giam-sat/tim-kiem-theo',
        method: 'GET',
        params,
      }),
    }),
    // Thống kê báo cáo
    getTKBCKyBaoCao: builder.query({
      query: (params) => ({
        url: '/api/enum/thong-ke-bao-cao/ky-bao-cao',
        method: 'GET',
        params,
      }),
    }),
    getTKBCQuarters: builder.query({
      query: (params) => ({
        url: '/api/enum/quarters',
        method: 'GET',
        params,
      }),
    }),
  })
})

export const {
  useGetHuongXuLyQuery,
  useGetHuongXuLyTiepDanQuery,
  useGetPhanLoaiDonQuery,
  useGetTrangThaiDonQuery,
  useGetTrangThaiDonChuyenQuery,
  useGetLoaiDoiTuongDungDonQuery,
  useGetQuocTichsQuery,
  useGetDonViHanhChinhTreeQuery,
  useGetNoiCapGiayTosQuery,
  useGetHuongGiaiQuyetDonQuery,
  useGetKetQuaKetLuanNoiDungQuery,
  useGetLoaiDoiTuongKhieuNaiQuery,
  useGetLinhVucDonThuQuery,
  useGetLinhVucDonThuChiTietQuery,
  useGetTrangThaiTheoDoiThucHienQuery,
  useGetKetQuaTheoDoiThucHienQuery,
  useGetDanTocsQuery,
  useGetTDGSPhanHeQuery,
  useGetTDGSTimKiemTheoQuery,
  useGetKetQuaGiaiQuyetLanDauQuery,
  useGetTKBCKyBaoCaoQuery,
  useGetTKBCQuartersQuery
} = enumApi;
