import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSearch: {
    timKiemTheo: 'YEAR',
  }
} 

const trangChuSlice = createSlice({
  name: 'trangChu',
  initialState,
  reducers: {
    setDataSearchTrangChu: (state, action) => {
      state.dataSearch = action.payload;
    },
    resetData: (state) => {
      state = initialState
    }
  },
});

export const { setDataSearchTrangChu, resetData, } = trangChuSlice.actions;
export const selectTrangChu = (state) => state?.trangChu?.dataSearch;
export default trangChuSlice.reducer;

