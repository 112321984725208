import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSearch: {}
}

const traCuuDonSlice = createSlice({
  name: 'traCuuDon',
  initialState,
  reducers: {
    setDataTraCuuDon: (state, action) => {
      state.dataSearch = action.payload;
    },
    resetDataTraCuuDon: (state) => {
      state.dataSearch = {}
    }
  },
});

export const { setDataTraCuuDon, resetDataTraCuuDon } = traCuuDonSlice.actions;
export const selectTraCuuDonSearch = (state) => state.traCuuDon.dataSearch;
export default traCuuDonSlice.reducer;