import { createSlice } from '@reduxjs/toolkit';
import { enumApi } from 'services/enum';

const initialState = {
  value: 0,
  dataNoiCapGiayTo: {},
  dataQuocTich: {},
  dataDonViHanhChinh: {}
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(enumApi.endpoints.getNoiCapGiayTos.matchFulfilled, (state, action) => {
        state.dataNoiCapGiayTo = action.payload
      })
      .addMatcher(enumApi.endpoints.getQuocTichs.matchFulfilled, (state, action) => {
        state.dataQuocTich = action.payload
      })
      .addMatcher(enumApi.endpoints.getDonViHanhChinhTree.matchFulfilled, (state, action) => {
        state.dataDonViHanhChinh = action.payload
      })

  },
})

export const { increment, decrement, incrementByAmount } = commonSlice.actions;

export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount))
  }, 1000)
}

export const selectCount = (state) => state.common.value;

export default commonSlice.reducer;
