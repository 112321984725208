import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  donHienThi: 'new'
}

const xuLyGiaiQuyetDonSlice = createSlice({
  name: 'xuLyGiaiQuyetDon',
  initialState,
  reducers: {
    setLoaiDon: (state, action) => {
      state.donHienThi = action.payload;
    },
    resetLoaiDon: (state) => {
      state.donHienThi = 'new'
    }
  },
});

export const { setLoaiDon } = xuLyGiaiQuyetDonSlice.actions;
export const selectLoaiDon = (state) => state.xuLyGiaiQuyetDon.donHienThi;
export default xuLyGiaiQuyetDonSlice.reducer;