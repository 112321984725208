import React from 'react';
import { Space, Spin } from 'antd'

const Loading = () => {
  return (
    <Space className="justify-center h-full w-full" size="middle">
      <Spin size="large" />
    </Space>
  )
}

export default Loading;