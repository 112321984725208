import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSearch: {}
}

const thongKeDonCuSlice = createSlice({
  name: 'thongKeDonCu',
  initialState,
  reducers: {
    setDataSearch: (state, action) => {
      state.dataSearch = action.payload;
    },
    resetData: (state) => {
      state.dataSearch = {}
    }
  },
});

export const { setDataSearch, resetData, setFirstLoad } = thongKeDonCuSlice.actions;
export const selectThongKeDonCuSearch = (state) => state.thongKeDonCu.dataSearch;
export default thongKeDonCuSlice.reducer;