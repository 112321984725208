import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tenCoQuan: [{
    chaId: "",
    tenCoQuan: "Danh mục cơ quan quản lý"
  }]
} 

const coQuanQuanLySlice = createSlice({
  name: 'coQuanQuanLy',
  initialState,
  reducers: {
    setDataCoQuanQuanLy: (state, action) => {
      state.tenCoQuan = action?.payload;
    },
    resetData: (state) => {
      state.tenCoQuan = []
    }
  },
});

export const { setDataCoQuanQuanLy, resetData, } = coQuanQuanLySlice.actions;
export const selectCoQuanQuanLy = (state) => state?.coQuanQuanLy?.tenCoQuan;
export default coQuanQuanLySlice.reducer;

