import { api } from "services/api";
import { exportFile } from 'helpers';

export const tiepCongDanApi = api.injectEndpoints({
  endpoints: builder => ({
    getTiepCongDans: builder.query({
      query: (params) => ({
        url: '/api/so-tiep-dan',
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) => {
        return result
          ? [...result?.items?.map(({ id }) => ({ type: 'TiepCongDan', id })), 'TiepCongDan']
          : ['TiepCongDan']
      }
    }),
    deleteTiepCongDan: builder.mutation({
      query: (id) => {
        return {
          url: `/api/so-tiep-dan/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['TiepCongDan'],
    }),
    postTiepCongDan: builder.mutation({
      query: (body) => {
        return {
          url: `/api/so-tiep-dan`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['TiepCongDan'],
    }),
    putTiepCongDan: builder.mutation({
      query: ({ id, params }) => {
        return {
          url: `/api/so-tiep-dan/${id}`,
          method: 'PUT',
          body: params
        }
      },
      invalidatesTags: ['TiepCongDan'],
    }),
    postLuotTiepCongDan: builder.mutation({
      query: ({ id, params }) => {
        return {
          url: `/api/luot-tiep-dan/${id}`,
          method: 'POST',
          body: params
        }
      },
      invalidatesTags: ['TiepCongDan'],
    }),
    postTuChoiTiep: builder.mutation({
      query: (body) => {
        return {
          url: `/api/so-tiep-dan/tu-choi-tiep`,
          method: 'POST',
          body: body
        }
      },
      invalidatesTags: ['TiepCongDan'],
    }),
    getChiTietTiepCongDan: builder.query({
      query: (id) => {
        return {
          url: `/api/so-tiep-dan/${id}`,
          method: 'GET',
        }
      }
    }),
    getChiTietTiepCongDanThuongXuyen: builder.query({
      query: (id) => {
        return {
          url: `/api/ho-so-tiep-dan-thuong-xuyen/${id}`,
          method: 'GET',
        }
      }
    }),
    getLichSuTiepCongDan: builder.query({
      query: (params) => {
        return {
          url: `/api/lich-su-tiep-cong-dan`,
          method: 'GET',
          params
        }
      }
    }),
    exportTiepCongDan: builder.query({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/api/export-excel-so-tiep-cong-dan`,
          params,
          responseHandler: ((response) => response.blob())
        })
        exportFile(result, 'tiep-cong-dan', 'xlsx')
        return { data: null }
      }
    }),
    exportGiayBienNhan: builder.query({
      queryFn: async (id, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/api/so-tiep-cong-dan/${id}/xuat-giay-bien-nhan`,
          responseHandler: ((response) => response.blob())
        })
        exportFile(result, 'giay-bien-nhan', 'docx')
        return { data: null }
      }
    }),
  })
});

export const {
  useGetTiepCongDansQuery,
  useDeleteTiepCongDanMutation,
  useLazyExportTiepCongDanQuery,
  useGetChiTietTiepCongDanQuery,
  useLazyGetChiTietTiepCongDanQuery,
  usePostTiepCongDanMutation,
  usePostLuotTiepCongDanMutation,
  usePutTiepCongDanMutation,
  useGetChiTietTiepCongDanThuongXuyenQuery,
  usePostTuChoiTiepMutation,
  useLazyGetLichSuTiepCongDanQuery,
  useLazyExportGiayBienNhanQuery
} = tiepCongDanApi;
