import { createSlice } from '@reduxjs/toolkit';
import { tiepCongDanApi } from 'services/tiepcongdan';

const initialState = {
  data: null,
  dataNguoiDungDon: [],
}

const tiepCongDanSlice = createSlice({
  name: 'tiepCongDan',
  initialState,
  reducers: {
    setDataNguoiDungDon: (state, action) => {
      state.dataNguoiDungDon = action.payload;
    },
    addNguoiDungDon: (state, action) => {
      if (state.dataNguoiDungDon.map(item => item?.id)?.includes(action?.payload?.id)) {
        return;
      } else {
        state.dataNguoiDungDon = [...state.dataNguoiDungDon, { ...action.payload, congDanId: action?.payload?.id }];
      }
    },
    removeNguoiDungDon: (state, action) => {
      state.dataNguoiDungDon = state.dataNguoiDungDon.filter((item, index) => index !== action.payload)
    },
    replaceNguoiDungDon: (state, action) => {
      state.dataNguoiDungDon = state.dataNguoiDungDon.map((item, index) => {
        if (index === action.payload.index) {
          return action.payload.data;
        }
        return item;
      })
    },
    resetDataNguoiDungDon: (state) => {
      state.dataNguoiDungDon = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(tiepCongDanApi.endpoints.getTiepCongDans.matchRejected, (state, action) => {
        state.data = action.payload
      })
  },
});

export const { addNguoiDungDon, removeNguoiDungDon, resetDataNguoiDungDon, setDataNguoiDungDon, replaceNguoiDungDon } = tiepCongDanSlice.actions;
export const selectTiepCongDan = (state) => state.tiepCongDan.data;
export const selectNguoiDungDon = (state) => state.tiepCongDan.dataNguoiDungDon;
export default tiepCongDanSlice.reducer;

